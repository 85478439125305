.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>a>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.logo-f{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size: 25px;
    color: #d3ae4d;
    text-align: center;
}