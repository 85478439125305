.Testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    background-color: transparent; /* Remove background color */
}

.intro-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
    color: wheat;
    margin-bottom: 2rem; /* Add space below intro text */
}

.intro-text > span:nth-child(1) {
    color: #b78532;
    font-weight: bold;
}

.intro-text > span:nth-child(2),
.intro-text > span:nth-child(3) {
    font-weight: bold;
    font-size: 2.5rem; /* Base font size */
}

.testimonial-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the content */
    gap: 1.5rem; /* Reduced gap for smaller screens */
    color: wheat;
    max-width: 800px; /* Set maximum width for larger screens */
    width: 100%; /* Full width within the max-width */
    text-align: center; /* Center-align text */
}

.review-text {
    text-transform: none;
    text-align: justify;
    letter-spacing: 1px; /* Reduced letter-spacing */
    line-height: 1.6; /* Adjusted line-height */
    font-size: 1rem; /* Base font size */
    overflow-wrap: break-word; /* Ensure long words break to fit the container */
}

.review-author {
    font-size: 1rem; /* Base font size */
    font-weight: bold;
    color: #b78532;
}

.arrows {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.arrows > img {
    width: 1.5rem; /* Base size for arrows */
    cursor: pointer;
}

/* Media Queries for Responsiveness */

/* Tablets and smaller devices */
@media (max-width: 768px) {
    .intro-text > span:nth-child(2),
    .intro-text > span:nth-child(3) {
        font-size: 2rem; /* Adjusted for tablets */
    }

    .review-text {
        font-size: 0.9rem; /* Adjusted for tablets */
    }

    .review-author {
        font-size: 0.9rem; /* Adjusted for tablets */
    }

    .arrows > img {
        width: 1.25rem; /* Adjusted for tablets */
    }
}

/* Mobile Phones */
@media (max-width: 480px) {
    .intro-text > span:nth-child(2),
    .intro-text > span:nth-child(3) {
        font-size: 1.5rem; /* Further reduction for mobile phones */
    }

    .review-text {
        font-size: 0.8rem; /* Further reduction for mobile phones */
    }

    .review-author {
        font-size: 0.8rem; /* Further reduction for mobile phones */
    }

    .arrows > img {
        width: 1rem; /* Further reduction for mobile phones */
    }
}
