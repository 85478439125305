.Services{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.services-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 4rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.service-categories{
    /* display: flex; */
    /* gap: 1rem; */
}

.category{
    /* display: flex; */
    /* flex-direction: column; */
    /* background-color: gray; */
    /* padding: 1.8rem; */
    /* gap: 1rem; */
    color: white;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    
    /* border: 2px solid #b78532; */
  padding: 10px; 
  margin-right: 10px;
  margin-left: 30px
}
/* 
.category:last-child {
    margin-right: 0;
  } */

.category>:nth-child(1){
    fill: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.category>:nth-child(2){
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    left: 200px;
}

/* .category:hover{
    width: 22rem;
    height: 19rem;
    background: #b78532;
    cursor: pointer;
} */