/* Existing Desktop Styles */
.Contact {
    display: flex;
    padding: 0 2rem;
    gap: 10rem; /* Space between left and right sections on desktop */
}

.link {
    text-decoration: none;
    color: black;
}

.left-c {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-c > hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid #b78532;
    border-radius: 20%;
    margin: -10px 0;
}

.left-c > div {
    display: flex;
    gap: 1rem;
}

.right-c {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container {
    gap: 3rem;
    padding: 1rem 2rem;
    background-color: gray;
    border-radius: 25px 0 0 25px;
    margin-left: 15rem;
}

.contact_info_sec h4 {
    letter-spacing: 1px;
    padding-bottom: 15px;
}

.info_single {
    margin: 30px 0px;
}

.info_single i {
    margin-right: 15px;
}

.info_single span {
    font-size: 14px;
    letter-spacing: 1px;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .Contact {
        flex-direction: column; /* Stack left and right sections vertically on mobile */
        gap: 2rem; /* Space between stacked sections on mobile */
        align-items: center; /* Center sections horizontally on mobile */
    }

    .left-c {
        font-size: 2rem; /* Adjust font size for better mobile display */
        text-align: center; /* Center text horizontally on mobile */
        position: static; /* Remove position styling for mobile */
    }

    .left-c > hr {
        display: none; /* Hide the horizontal line on mobile */
    }

    .left-c > div {
        flex-direction: column; /* Stack text spans vertically on mobile */
        align-items: center; /* Center text spans horizontally on mobile */
    }

    .right-c {
        width: 100%; /* Make sure the right section takes full width on mobile */
        padding: 1rem; /* Add padding for better spacing */
        box-sizing: border-box; /* Include padding in element's total width and height */
        align-items: flex-start; /* Align items to the start on mobile */
    }

    .email-container {
        width: 100%; /* Full width for the container on mobile */
        margin: 0; /* Remove margin for better alignment */
        border-radius: 0; /* Remove border radius for mobile */
    }

    .contact_info_sec {
        background-color: #2d2d2d; /* Ensure background color is consistent */
        width: 100%; /* Full width on mobile */
        padding: 20px; /* Adjust padding for better mobile display */
        border-radius: 0; /* Remove border radius for mobile */
    }
}
