.Reasons {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    justify-content: space-between; /* Ensure space between the left and right sections */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Left section layout */
.left-r {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
    gap: 1rem;
    grid-auto-rows: 1fr;
    flex: 1; /* Allow it to take available space */
    width: 100%;
}

.left-r img {
    object-fit: cover;
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.left-r > :nth-child(1) {
    width: 22rem;
    grid-row: 1 / 3; /* Span two rows */
    height: 28rem; /* Adjust as needed */
}

.left-r > :nth-child(2) {
    width: 33rem;
    height: 16rem; /* Adjust as needed */
    grid-column: 2 / 4; /* Span two columns */
}

.left-r > :nth-child(3) {
    width: 22rem;
    height: 11rem; /* Adjust as needed */
    grid-column: 2 / 3; /* Place in first column of second row */
    grid-row: 2; /* Second row */
}

.left-r > :nth-child(4) {
    width: 10rem;
    height: 11rem; /* Adjust as needed */
    grid-row: 2; /* Second row */
    grid-column: 3 / 4; /* Place in second column of second row */
}

/* Right section layout */
.right-r {
    flex: 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure it takes available space */
}

/* Mobile View */
@media (max-width: 768px) {
    .Reasons {
        padding: 0 1rem; /* Reduce padding */
        flex-direction: column; /* Stack vertically on tablets */
    }

    .left-r {
        grid-template-columns: repeat(2, 1fr); /* 2 columns layout on tablets */
    }

    .left-r > :nth-child(1) {
        grid-column: 1 / 3; /* Span 2 columns */
        height: 20rem; /* Adjust as needed */
    }

    .left-r > :nth-child(2) {
        grid-column: 1 / 3; /* Span 2 columns */
        height: 16rem; /* Adjust as needed */
    }

    .left-r > :nth-child(3) {
        grid-column: 1; /* Place in first column */
        height: 12rem; /* Adjust height as needed */
    }

    .left-r > :nth-child(4) {
        grid-column: 2; /* Place in second column */
        height: 12rem; /* Adjust height as needed */
    }

    .right-r {
        text-align: center; /* Center align text on smaller screens */
        width: 100%; /* Full width on tablets */
    }
}

/* Mobile Phones */
@media (max-width: 480px) {
    .Reasons {
        padding: 0 1rem; /* Further reduce padding */
    }

    .left-r {
        grid-template-columns: 1fr; /* Single column layout on mobile */
    }

    .left-r > img {
        display: none; /* Hide all images */
    }

    .left-r > :nth-child(2){
        display: block; /* Show only the 2nd and 3rd images */
        height: 15rem; /* Adjust height for mobile */
        width: 100%;
        align-items: center;
    }

    .left-r > :nth-child(3) {
        display: block; /* Show only the 2nd and 3rd images */
        height: 15rem; /* Adjust height for mobile */
        width: 105%;
        justify-content: center;
        align-items: center;
    }

    .right-r {
        text-align: center; /* Center align text on mobile */
        width: 100%; /* Full width on mobile */
    }
}

.right-r > span {
    font-weight: bold;
    color: var(--orange);
    font-size: 1.5rem; /* Adjust font size for smaller screens */
}

.right-r > div {
    color: white;
    font-size: 2rem; /* Adjust font size */
    font-weight: bold;
}

/* Details section styling */
.details-r img {
    height: 1.5rem;
    width: 1.5rem;
}

.details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r > div {
    display: flex;
    font-size: 0.9rem;
    gap: 1rem;
    align-items: center;
}
