.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.8); /* Slightly darker background for contrast */
    position: relative;
    z-index: 1000; /* Ensure it's above other content */
}

.logo {
    width: 4rem; /* Adjust logo size */
    height: auto;
    cursor: pointer;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem; /* Spacing between menu items */
    color: white;
}

.header-menu li {
    font-size: 1rem; /* Font size for menu items */
}

.header-menu li:hover {
    cursor: pointer;
    color: rgb(133, 133, 166);
}

.contact-btn {
    color: white;
    background-color: #b78532;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px; /* Optional: rounded corners */
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px; /* Sidebar width */
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    padding: 1rem; /* Adjust padding */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1001; /* Ensure it's above other content */
    display: none; /* Hide sidebar by default */
    flex-direction: column; /* Stack items vertically */
}

.sidebar.open {
    transform: translateX(0);
    display: flex; /* Show sidebar when open */
}

.sidebar-menu {
    list-style: none;
    display: flex;
    flex-direction: column; /* Stack menu items vertically */
    gap: 1rem;
    color: white;
    flex-grow: 1; /* Allow menu items to take available space */
}

.sidebar-menu li {
    font-size: 1.2rem; /* Adjust font size */
}

.sidebar-menu li:hover {
    cursor: pointer;
    color: rgb(133, 133, 166);
}

.close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

/* Sidebar button styles */
.sidebar-buttons {
    margin-top: auto; /* Push the button to the bottom of the sidebar */
}

.sidebar-buttons .btn {
    color: white;
    background-color: #b78532;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .header-menu {
        display: none; /* Hide the header menu on mobile screens */
    }

    .contact-btn {
        display: none; /* Optionally hide the button on very small screens if needed */
    }

    .menu-btn {
        display: block; /* Show the menu button on mobile screens */
        background: none;
        border: none;
        color: white;
        font-size: 2rem;
        cursor: pointer;
    }

    .sidebar {
        display: flex; /* Show sidebar on mobile screens */
    }
}

@media (min-width: 769px) {
    .sidebar {
        display: none; /* Ensure sidebar is hidden on desktop screens */
    }

    .menu-btn {
        display: none; /* Hide the menu button on desktop screens */
    }
}
