.home {
    background-image: url('../../assets/front-img_1.jpg');
    display: flex;
    justify-content: space-between;
    background-size: cover;
    filter: brightness(0.8);
    position: relative;
    min-height: 100vh; /* Ensure it covers the viewport height */
    flex-direction: column; /* Stack elements vertically by default */
}

/* Adjust layout for larger screens */
@media (min-width: 768px) {
    .home {
        flex-direction: row; /* Align elements horizontally on larger screens */
    }
}

.left-h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h {
    flex: 0.3;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
}

.the-best-ad {
    margin-top: 4rem;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: #b78532;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
}

.the-best-ad > span {
    z-index: 2;
}

.the-best-ad > div {
    position: absolute;
    background-color: rgb(44, 96, 159);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.title-text {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.home-buttons {
    display: flex;
    gap: 1rem;
    font-weight: normal;
}

.home-buttons > .btn {
    color: white;
    background-color: #b78532;
    width: 8rem;
    padding: 10px;
    border: none;
    cursor: pointer;
    text-align: center;
}

.home-buttons > .btn:nth-child(2) {
    background-color: transparent;
    border: 2px solid #b78532;
}

.right-h > .btn {
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
    background: #b78532;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .title-text {
        font-size: 3rem; /* Smaller font size for mobile devices */
    }

    .home-buttons > .btn {
        width: 6rem; /* Adjust button size */
    }

    .the-best-ad {
        margin-top: 2rem; /* Less margin on smaller screens */
    }
}

@media (max-width: 480px) {
    .title-text {
        font-size: 2rem; /* Further reduce font size on very small screens */
    }

    .home-buttons > .btn {
        width: 5rem; /* Further adjust button size */
    }

    .left-h, .right-h {
        padding: 1rem; /* Reduce padding on smaller screens */
    }
}
